import React from 'react';
import Loading from './components/LoadingOverlay/Loading';
import verityLogoBig from './assets/images/verity_logo.svg';
import './App.scss';

const App = React.lazy(() => import('./App'));

const AppContainer = () => {
  return (
    <React.Suspense
      fallback={
        <Loading loading={true}>
          <div
            style={{
              margin: 0,
              minHeight: '80vh',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <img
              style={{ marginTop: '132px' }}
              src={verityLogoBig}
              alt="Verity"
            />
          </div>
        </Loading>
      }
    >
      <App />
    </React.Suspense>
  );
};

export default AppContainer;
