import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import AppContainer from './AppContainer';
import * as Sentry from '@sentry/react';
import { Integrations as TracingIntegrations } from '@sentry/tracing';

Sentry.init({
  dsn:
    'https://533daa7f1b3c4b1aa21b4b159eb5794e@o495705.ingest.sentry.io/5573339',

  // This enables automatic instrumentation (highly recommended), but is not
  // necessary for purely manual usage
  integrations: [
    new TracingIntegrations.BrowserTracing({
      // tracingOrigins: ['http://localhost:3000', 'https://devapi.verity.app'],
    }),
  ],
  // You will need to configure your web server CORS to allow the sentry-trace header.
  // The configuration might look like "Access-Control-Allow-Headers: sentry-trace",
  // but the configuration depends on your set up. If you do not allow the sentry-trace
  // header, the request might be blocked.

  // To set a uniform sample rate
  tracesSampleRate: 0.5,
  environment: !process.env.REACT_APP_DEV_MODE ? 'production' : 'development',
});

ReactDOM.render(<AppContainer />, document.getElementById('root'));
